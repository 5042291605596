"use client";

import React, { useMemo, useState } from "react";

type MODAL_VIEWS =
  | "SIGN_UP_VIEW"
  | "LOGIN_VIEW"
  | "FORGET_PASSWORD"
  | "PRODUCT_VIEW"
  | "PRODUCT_WARNING"
  | "UPDATE_ITEM_CONFIRMATION"
  | "ADDRESS_EDIT_FORM"
  | "CONFIRM_CARD_DELETE"
  | "SPLIT_SHIPMENT"
  | "REGISTRATION_SUCCESS"
  | "PRODUCTION_UPGRADE_WARNING"
  | "ADDRESS_CREATE_FORM"
  | "HARD_COPIES"
  | "ADDRESS_DELETE"
  | "VERIFY_EMAIL"
  | "CHANGE_PASSWORD_SUCCESS"
  | "INCOMPATIBILITIES"
  | "CANCEL_ORDER_CONFIRMATION";

type ModalUISettings = { header?: string };

type ModalConfig = {
  view: MODAL_VIEWS;
  data?: unknown;
  settings?: ModalUISettings;
  autoOpen?: boolean;
};

interface UIState {
  displaySidebar: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;

  displayModal: boolean;
  openModal: () => void;
  closeModal: () => void;

  displayCart: boolean;
  openCart: () => void;
  closeCart: () => void;

  displaySearch: boolean;
  openSearch: () => void;
  closeSearch: () => void;

  modalData: unknown | null;
  modalUISettings: { header?: string };
  modalView: MODAL_VIEWS;
  setModalConfig: (data: ModalConfig) => void;
}

const UIContext = React.createContext<UIState | undefined>(undefined);

export function UIProvider({ children }: { children: React.ReactNode }) {
  const [displaySidebar, setDisplaySidebar] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [displayCart, setDisplayCart] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalView, setModalView] = useState<MODAL_VIEWS>("LOGIN_VIEW");
  const [modalUISettings, setModalUISettings] = useState<UIState["modalUISettings"]>({});
  const [modalData, setModalData] = useState<unknown | null>(null);

  const value = useMemo(
    () => ({
      displaySidebar,
      openSidebar: () => setDisplaySidebar(true),
      closeSidebar: () => setDisplaySidebar(false),

      displaySearch,
      openSearch: () => setDisplaySearch(true),
      closeSearch: () => setDisplaySearch(false),

      displayCart,
      openCart: () => setDisplayCart(true),
      closeCart: () => setDisplayCart(false),

      displayModal,
      openModal: () => setDisplayModal(true),
      closeModal: () => setDisplayModal(false),

      modalView,
      modalUISettings,
      modalData,
      setModalConfig: ({ view, data, settings, autoOpen }: ModalConfig) => {
        setModalView(view);
        settings && setModalUISettings(settings);
        data && setModalData(data);
        autoOpen && setDisplayModal(true);
      },
    }),
    [
      displaySidebar,
      displaySearch,
      displayCart,
      displayModal,
      modalView,
      modalUISettings,
      modalData,
    ],
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
}

export const useUI = <T,>() => {
  const context = React.useContext(UIContext) as UIState & { modalData: T };
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};
