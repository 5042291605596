import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeCategoryPageSkeleton } from "./TypeCategoryPage";
import type { TypeContentPageSkeleton } from "./TypeContentPage";
import type { TypeContentWithFormPageSkeleton } from "./TypeContentWithFormPage";
import type { TypeNavSecondLevelSkeleton } from "./TypeNavSecondLevel";
import type { TypeProductPageSkeleton } from "./TypeProductPage";

/**
 * Fields type definition for content type 'TypeNavFirstLevel'
 * @name TypeNavFirstLevelFields
 * @type {TypeNavFirstLevelFields}
 * @memberof TypeNavFirstLevel
 */
export interface TypeNavFirstLevelFields {
    /**
     * Field type definition for field 'internalName' (Internal Name)
     * @name Internal Name
     * @localized false
     */
    internalName?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'name' (Name)
     * @name Name
     * @localized true
     */
    name: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'targetUrl' (Target URL)
     * @name Target URL
     * @localized false
     */
    targetUrl?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'targetPage' (Target Page)
     * @name Target Page
     * @localized false
     */
    targetPage?: EntryFieldTypes.EntryLink<TypeCategoryPageSkeleton | TypeContentPageSkeleton | TypeContentWithFormPageSkeleton | TypeProductPageSkeleton>;
    /**
     * Field type definition for field 'items' (Items)
     * @name Items
     * @localized false
     */
    items: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeNavSecondLevelSkeleton>>;
    /**
     * Field type definition for field 'highlighted' (Highlighted)
     * @name Highlighted
     * @localized false
     */
    highlighted?: EntryFieldTypes.Boolean;
    /**
     * Field type definition for field 'highlightBackgroundColour' (Highlight Background Colour)
     * @name Highlight Background Colour
     * @localized false
     */
    highlightBackgroundColour?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'highlightTextColour' (Highlight Text Colour)
     * @name Highlight Text Colour
     * @localized false
     */
    highlightTextColour?: EntryFieldTypes.Symbol;
}

/**
 * Entry skeleton type definition for content type 'navFirstLevel' (🎛 Nav First Level)
 * @name TypeNavFirstLevelSkeleton
 * @type {TypeNavFirstLevelSkeleton}
 * @author 4cbPwqAt5JzV5Z1U9eQ7iG
 * @since 2024-08-19T08:44:10.865Z
 * @version 7
 */
export type TypeNavFirstLevelSkeleton = EntrySkeletonType<TypeNavFirstLevelFields, "navFirstLevel">;
/**
 * Entry type definition for content type 'navFirstLevel' (🎛 Nav First Level)
 * @name TypeNavFirstLevel
 * @type {TypeNavFirstLevel}
 * @author 4cbPwqAt5JzV5Z1U9eQ7iG
 * @since 2024-08-19T08:44:10.865Z
 * @version 7
 */
export type TypeNavFirstLevel<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeNavFirstLevelSkeleton, Modifiers, Locales>;

export function isTypeNavFirstLevel<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeNavFirstLevel<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'navFirstLevel'
}
