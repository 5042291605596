import { Cart, LineItem, Order } from "@commercetools/platform-sdk";
import * as Sentry from "@sentry/nextjs";
import { getServerSession } from "next-auth";
import { getSession as getClientSession } from "next-auth/react";

import { authOptions } from "@/app/api/auth/[...nextauth]/auth";
import { ProductPage } from "@/clients/contentful";
import { getPriceForConfig } from "@/clients/product-config/product";
import { fetchMe } from "@/features/account/hooks/use-me";
import { fetchCart } from "@/features/basket/hooks/use-cart";
import * as gtmEvents from "./gtm/events";
import * as klaviyoEvents from "./klaviyo/events";

export async function trackAddToCartEvent(args: { lineItem: LineItem; cart?: Cart }) {
  const { lineItem, cart: crt } = args;
  const cart = crt ?? (await getCart());
  if (!cart) return;

  safeExec(
    () => gtmEvents.trackAddToCartEvent({ lineItem, cart }),
    () => klaviyoEvents.trackAddToCartEvent({ lineItem, cart }),
  );
}

export async function trackRemoveFromCartEvent(args: { lineItem: LineItem; cart?: Cart }) {
  const { lineItem, cart: crt } = args;
  const cart = crt ?? (await getCart());
  if (!cart) return;

  safeExec(() => gtmEvents.trackRemoveFromCartEvent({ lineItem, cart }));
}

export function trackViewCartEvent({ cart }: { cart: Cart }) {
  safeExec(() => gtmEvents.trackViewCartEvent({ cart }));
}

export function trackCheckoutEvent({ cart }: { cart: Cart }) {
  safeExec(
    () => gtmEvents.trackCheckoutEvent({ cart }),
    () => klaviyoEvents.trackCheckoutEvent({ cart }),
  );
}

export async function trackViewItemEvent({ product }: { product: ProductPage }) {
  const user = await getSession().then((session) => fetchMe(session));
  const price = (
    await getPriceForConfig({
      sku: product.fields.sku!,
      config: null,
      metadata: { customerId: user?.id },
    })
  )?.unit!;
  safeExec(
    () => gtmEvents.trackViewItemEvent({ product, price }),
    () => klaviyoEvents.trackViewItemEvent({ product, price }),
  );
}

export function trackPurchaseEvent({ order }: { order: Order }) {
  safeExec(() => gtmEvents.trackPurchaseEvent({ order }));
}

export function trackViewPageEvent({ url }: { url: string }) {
  safeExec(() => klaviyoEvents.trackViewedPageEvent({ url }));
}

export async function trackLoginEvent() {
  const user = await getSession().then((session) => fetchMe(session));
  if (!user) return;

  safeExec(() => gtmEvents.trackLoginEvent({ user }));
}

function getSession() {
  return typeof window === "undefined" ? getServerSession(authOptions) : getClientSession();
}

function getCart() {
  return getSession().then((session) => fetchCart(session));
}

/**
 * Safely runs an array of track events functions,
 * catching any errors they may throw and handling them by reporting to Sentry.
 *
 * @param {...Function} fns
 * @returns {Promise<void>}
 */
function safeExec(...fns: Array<() => void | Promise<void>>) {
  return fns.forEach(async (fn) => {
    try {
      await fn();
    } catch (err) {
      Sentry.captureMessage("Error while executing the logic to track the event", {
        extra: { err },
      });
      console.error("Error while executing the logic to track the event", err);
    }
  });
}
